import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UCSFLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Smith"
    institution="UCSF"
    referralCode="UCSFJS"
    physicianURL="https://urology.ucsf.edu/people/james-f-smith"
  />
)

export default UCSFLandingPage
